import React from 'react';
import { Button as BaseButton } from 'baseui/button';
import { useStyletron } from 'baseui/styles';
const Button = ({
  kind = 'primary',
  size = 'default',
  children,
  onClick,
  testId = 'button',
  disabled,
  loading,
  overrides
}) => {
  let styleOverrides = overrides ?? {
    Root: {
      style: ({
        $theme
      }) => ({
        fontSize: '14px'
      })
    }
  };
  let buttonKind = kind;
  switch (kind) {
    case 'success':
      styleOverrides = {
        ...styleOverrides,
        Root: {
          ...styleOverrides['Root'],
          style: ({
            $theme
          }) => ({
            backgroundColor: $theme.colors.positive,
            fontSize: '14px',
            ':hover': {
              backgroundColor: !loading && !disabled ? $theme.colors.positive300 : undefined
            }
          })
        }
      };
      buttonKind = 'primary';
      break;
    case 'error':
      styleOverrides = {
        ...styleOverrides,
        Root: {
          ...styleOverrides['Root'],
          style: ({
            $theme
          }) => ({
            backgroundColor: $theme.colors.negative,
            color: $theme.colors.white,
            fontSize: '14px',
            ':hover': {
              backgroundColor: !loading && !disabled ? $theme.colors.negative300 : undefined
            }
          })
        }
      };
      buttonKind = 'primary';
      break;
    case 'primary':
      buttonKind = 'primary';
      overrides = {};
      break;
    case 'secondary':
      buttonKind = 'secondary';
      break;
    case 'tertiary':
      buttonKind = 'tertiary';
      break;
  }
  return <BaseButton kind={buttonKind} size={size} data-testid={testId} onClick={onClick} disabled={disabled} overrides={styleOverrides} isLoading={loading}>
      {children}
    </BaseButton>;
};
export const ButtonRow = ({
  children,
  flexPosition,
  shrinkDirection,
  testId
}) => {
  const [css] = useStyletron();
  return <div className={css({
    display: 'flex',
    justifyContent: flexPosition ?? 'flex-start',
    gap: '16px',
    alignItems: 'center',
    '@media only screen and (max-width: 768px)': {
      flexDirection: shrinkDirection ? shrinkDirection : 'column'
    }
  })} data-testid={testId ?? 'button-row'}>
      {children}
    </div>;
};
export default Button;